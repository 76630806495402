// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bushido-js": () => import("./../../../src/pages/bushido.js" /* webpackChunkName: "component---src-pages-bushido-js" */),
  "component---src-pages-hurray-js": () => import("./../../../src/pages/hurray.js" /* webpackChunkName: "component---src-pages-hurray-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klienti-js": () => import("./../../../src/pages/klienti.js" /* webpackChunkName: "component---src-pages-klienti-js" */),
  "component---src-pages-o-mne-js": () => import("./../../../src/pages/oMne.js" /* webpackChunkName: "component---src-pages-o-mne-js" */),
  "component---src-pages-o-tebe-js": () => import("./../../../src/pages/oTebe.js" /* webpackChunkName: "component---src-pages-o-tebe-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}

